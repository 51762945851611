import React from 'react';
import { Row, Space } from 'antd';
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';

const NicoleCat = require('../images/NicoleCat.png');

const Contact = (props) => {
    const {} = props;

    return (
        <Space align='center'>
            <div>
                <br/>
                <img src={NicoleCat} width="200px" />
                <br/>
            </div>
            <div>
                <br/>
                <h4 className="footerHeader">Contact me!</h4>
                <Space>
                    <WhatsAppOutlined style={{ color: '#F1EBDE', fontSize: '28px' }} onClick={() => { window.open('https://wa.me/27658037012', '_blank'); }} />
                    <MailOutlined style={{ color: '#F1EBDE', fontSize: '28px' }} onClick={() => { window.open('mailto:nicolech35@gmail.com', "_blank"); }} />
                </Space>
            </div>
        </Space>
    );
};

export default Contact;