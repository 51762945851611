import React, { useState, useRef, useEffect } from 'react';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import Experience from './components/Experience';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';

const NicoleCat = require('./images/NicoleCat.png');
const NicoleProfile = require('./images/NicoleHampshire.png');
const NicoleCV = require('./images/NicoleHampshire-CV-2023.pdf');

const Home = (props) => {
    const [hash, setHash] = useState('');
    const scrolledRef = useRef(false);

    useEffect(() => {
        if (hash && !scrolledRef.current) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
              scrolledRef.current = true;
            }
        }
    }, [hash]);

    return (
        <div>
            <nav className="navbar navbar-expand-sm bg-transparent navbar-light">
                <div className="container">
                    {/* Brand */}
                    <a className="navbar-brand" href="#">
                        <img src={NicoleCat} width="120px" />
                    </a>
                
                    {/* Toggler/collapsibe Button */}
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
        
                    {/* Navbar links */}
                    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <a id="aboutNav" className="nav-link active" href="#about" onClick={() => { setHash('#about'); }}>ABOUT<div className="navActive navActiveStyle"></div></a>
                            </li>
                            <li className="nav-item">
                                <a id="skillNav" className="nav-link" href="#skills" onClick={() => { setHash('#skills'); }}>SKILLS<div className="navActive"></div></a>
                            </li>
                            <li className="nav-item">
                                <a id="portfolioNav" className="nav-link" href="#portfolio" onClick={() => { setHash('#portfolio3'); }}>PORTFOLIO<div className="navActive"></div></a>
                            </li> 
                            <li className="nav-item">
                                <a id="contactNav" className="nav-link" href="#contact" onClick={() => { setHash('#contact'); }}>CONTACT<div className="navActive"></div></a>
                            </li>
                        </ul>
                        <Space style={{ paddingLeft: 15 }}>
                            <LinkedinOutlined style={{ color: '#E4938C', fontSize: '28px' }} onClick={() => { window.open('https://www.linkedin.com/in/nicole-hampshire-537787136/', '_blank'); }} />
                            <FacebookOutlined style={{ color: '#E4938C', fontSize: '28px' }} onClick={() => { window.open('https://www.facebook.com/nicole.hampshire.10', '_blank'); }} />
                            <InstagramOutlined style={{ color: '#E4938C', fontSize: '28px' }} onClick={() => { window.open('https://www.instagram.com/nicolech35/', '_blank'); }} />
                            {/* <GithubOutlined style={{ color: '#E4938C', fontSize: '28px' }} onClick={() => { window.open('https://github.com/NicoleCH35', '_blank'); }} /> */}
                        </Space>
                    </div> 
                </div>
            </nav>

            <div id="about" className="container-fluid">
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-8 justify-content-center align-self-center">
						<div className="aboutSection">
							<h1 className="aboutName animated fadeInLeft"><span className="pinkText">NICOLE</span> HAMPSHIRE</h1>
							<div className="animated fadeIn delay-1s" style={{borderLeft: '5px solid #F1EBDE', borderRadius: '3px'}}>
								<p className="aboutDesc" style={{paddingLeft: '10px'}}>
									I graduated from the University of Pretoria with
									a BIS Multimedia degree, this degree is unique in that it combines Computer Science modules with Design modules. 
								</p>
							</div>
							<span className="row animated fadeIn delay-2s">
								<a id="portfolioLink" className="aboutBtn btn-1" href="#portfolio" width="50%">PORTFOLIO</a>
								<a id="cvLink" className="aboutLineBtn btn-2" href={NicoleCV} download="NicoleHampshireCV" target="_blank" rel="noreferrer">CV DOWNLOAD</a>
							</span>
						</div>
					</div>
					<div className=" col-xs-12 col-sm-12 col-md-4 justify-content-center align-self-center">
						<img className="img-responsive rounded-circle aboutImg animated fadeInRight" src={NicoleProfile} width="100%"/>
					</div>
				</div>
		    </div>

            <div id="skills" className="container-fluid">
                <div className="container">
                    <br/>
                    <br/>
                    <div className="row" style={{paddingTop: '10px'}}>
                        <div className="col-xs-12 col-sm-12 col-md-5 justify-content-center align-self-center">
                            <h1 className="skillName">EXPERIENCE &amp; <span className="pinkText">SKILL</span></h1>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 justify-content-center align-self-center">
                            <div className="aboutSection">
                                <div style={{borderLeft: '5px solid #E4938C', borderRadius:'3px'}}>
                                    <p className="skillDesc" style={{paddingLeft: '10px'}}>
                                        I'm a dedicated worker that can work well individually or in a team, I learn quickly, manage time well and can easily adapt to new skills. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <div className="">
                        <div className="row">
                            <Experience />
                        </div>
                    </div>
                </div>
            </div>

            <div id="skillBars" className="container-fluid">
                <br/>
                <br/>
                <br/>

                <div className="container">
                    <h1 className="skillBoxName">PROGRAMMING LANGUAGES AND <span className="pinkText">FRAMEWORKS</span></h1>
                    <div style={{ borderLeft: '5px solid #E4938C', borderRadius: '3px' }}>
                        <p className="skillBoxDesc" style={{ paddingLeft: '10px' }}>
                            React, React Native, Expo, GraphQL, HTML5, Javascript, CSS, Bootstrap, JQuery, Ajax, JSON, PHP, NodeJS, Electron, C#, C++, Java, Delphi, SQL, XML, WebGL, Assembly
                        </p>
                    </div>
                </div>
                <br/>
                <br/>

                <div className="container">
                    <h1 className="skillBoxName">SOFTWARE</h1>
                    <div style={{ borderLeft: '5px solid #E4938C', borderRadius: '3px' }}>
                        <p className="skillBoxDesc" style={{ paddingLeft: '10px' }}>
                            Xcode, Android Studio, Visual Studio Code, Jira, Confluence, Bitbucket, Docker, Firebase, CodePush, Adobe Illustrator, Adobe Photoshop, Adobe InDesign, Adobe After Effects, Adobe Premier Pro, Adobe Flash (Animate), PhoneGap, 3DS Max, Unity, Microsoft Word, Microsoft Excel, Microsoft PowerPoint
                        </p>
                    </div>
                </div>
                <br/>
                <br/>

                <div className="container">
                    <h1 className="skillBoxName">SKILLS</h1>
                    <div style={{ borderLeft: '5px solid #E4938C', borderRadius: '3px' }}>
                        <p className="skillBoxDesc" style={{ paddingLeft: '10px' }}>
                            Web Design and Development, App Design and Development, UX/UI Design, Graphic Design, Multimedia Design, Game Design and Development, Copy Editing, Project Management, Software Development, 3D Modelling, 3D Rigging, 3D Texturing, 3D Animating, 2D Animation
                        </p>
                    </div>
                </div>

                <br/>
                <br/>
            </div>

            <div id="portfolio" className="container-fluid">
                <div className="container">
                    <br/>
                    <br/>
                    <div className="row"  style={{paddingTop: '10px'}}>
                        <div className="col-xs-12 col-sm-12 col-md-5 justify-content-center align-self-center">
                            <h1 className="skillName">PORTFOLIO</h1>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-7 justify-content-center align-self-center">
                            <div className="aboutSection">
                                <div style={{borderLeft: '5px solid #E4938C', borderRadius: '3px'}}>
                                    <p className="skillDesc" style={{paddingLeft: '10px'}}>
                                        This portfolio includes a collection of work I did during University, while freelancing and while working at other companies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container">
                <br/>
                <br/>
                <Portfolio />
            </div>
            <br/>

            <footer id="contact">
                <div className="container">
                    <br/>
                    <Contact />
                    <br/>
                    <br/>
                </div>
            </footer>
        </div>
    );
};

export default Home;