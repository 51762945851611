import React from 'react';

const Media1 = require('../images/portfolio/alvernCatalogue.png');
const Media2 = require("../images/portfolio/haydonsSpice.png");
const Media3 = require("../images/portfolio/heardatLogoAnimationLoop.gif");
const Media4 = require("../images/portfolio/visionNotepad.png");
const Media5 = require("../images/portfolio/igitaWebsite.png");
const Media6 = require("../images/portfolio/halpWebsite.png");
const Media7 = require("../images/portfolio/alvernVideo.mp4");
const Media8 = require("../images/portfolio/zydia.png");
const Media9 = require("../images/portfolio/copperTradeInfo.png");
const Media10 = require("../images/portfolio/copperWebsite.gif");
const Media11 = require("../images/portfolio/alvernSocial.png");
const Media12 = require("../images/portfolio/PSI-InstaStories.mp4");
const Media13 = require("../images/portfolio/epoxeriteWebsite.png");
const Media14 = require("../images/portfolio/visionBanner.png");
const Media15 = require("../images/portfolio/weddingVideo.mp4");
const Media16 = require("../images/portfolio/heardatAnimation.mp4");
const Media17 = require("../images/portfolio/dreyerLogo.png");
const Media18 = require("../images/portfolio/psi-infographic.png");
const Media19 = require("../images/portfolio/yvdwBanner.png");
const Media20 = require("../images/portfolio/imperfectVideo.mp4");
const Media21 = require("../images/portfolio/baldoVinosMenu.png");
const Media22 = require("../images/portfolio/universalNewspaper.png");
const Media23 = require("../images/portfolio/PostXMessaging.png");
const Media24 = require("../images/portfolio/PostXSchedule.png");
const Media25 = require("../images/portfolio/AutoConnect.png");
const Media26 = require("../images/portfolio/Optiphi.png");

const Portfolio = (props) => {
    const {} = props;

    const portfolioItems = [
        {
            client: 'POSTX',
            title: 'MESSAGING WEB & MOBILE APP',
            image: Media23,
            company: 'Reach Engage Develop',
        },
        {
            client: 'AUTOCONNECT',
            title: 'APP STORE SCREENSHOTS & DEVELOPMENT',
            image: Media25,
            company: 'ComuneIT',
        },
        {
            client: 'ALVERN CABLES',
            title: 'CABLE CATALOGUE',
            image: Media1,
            company: 'Gingercats Creative',
        },
        {
            client: 'HAYDON\'S FAMILY BUTCHER',
            title: 'SPICE PACKAGING',
            image: Media2,
            company: 'Gingercats Creative',
        },
        {
            client: 'HEARDAT',
            title: 'LOGO ANIMATION',
            image: Media3,
            company: 'Gingercats Creative',
        },
        {
            client: 'VISION PROPERTIES',
            title: 'NOTEPAD',
            image: Media4,
            company: 'Gingercats Creative',
        },
        {
            client: 'IGITA',
            title: 'WEBSITE',
            image: Media5,
            company: 'Gingercats Creative',
        },
        {
            client: 'HALP MEDIA',
            title: 'WEBSITE',
            image: Media6,
            company: 'Gingercats Creative',
        },
        {
            client: 'ALVERN CABLES',
            title: 'MANUFACTURING PROCESS VIDEO',
            video: Media7,
            company: 'Gingercats Creative',
        },
        {
            client: 'SURVIVING ZYDIA',
            title: 'GAME DEVELOPMENT',
            image: Media8,
            company: 'University Project',
        },
        {
            client: 'POSTX',
            title: 'SOCIAL CONTENT SCHEDULER',
            image: Media24,
            company: 'Reach Engage Develop',
        },
        {
            client: 'COPPER & MALT',
            title: 'TRADE INFORMATION',
            image: Media9,
            company: 'Gingercats Creative',
        },
        {
            client: 'COPPER & MALT',
            title: 'WEBSITE',
            image: Media10,
            company: 'Gingercats Creative',
        },
        {
            client: 'ALVERN CABLES',
            title: 'SOCIAL MEDIA',
            image: Media11,
            company: 'Gingercats Creative',
        },
        {
            client: 'PIERRE SPIES INTERNATIONAL SPORT INSTITUTE',
            title: 'INSTAGRAM STORIES',
            video: Media12,
            company: 'Gingercats Creative',
        },
        {
            client: 'EPOXERITE',
            title: 'WEBSITE',
            image: Media13,
            company: 'Gingercats Creative',
        },
        {
            client: 'VISION PROPERTIES',
            title: 'WALL BANNER',
            image: Media14,
            company: 'Gingercats Creative',
        },
        {
            client: 'LINDI & DEWALD',
            title: 'WEDDING VIDEO',
            video: Media15,
            company: 'Gingercats Creative',
        },
        {
            client: 'OPTIPHI',
            title: 'APP STORE SCREENSHOTS & DEVELOPMENT',
            image: Media26,
            company: 'ComuneIT',
        },
        {
            client: 'HEARDAT',
            title: 'ANIMATION',
            video: Media16,
            company: 'Gingercats Creative',
        },
        {
            client: 'DREYER & DREYER',
            title: 'LOGO',
            image: Media17,
            company: 'Gingercats Creative',
        },
        {
            client: 'PIERRE SPIES INTERNATIONAL SPORT INSTITUTE',
            title: 'INFOGRAPHIC',
            image: Media18,
            company: 'Gingercats Creative',
        },
        {
            client: 'YVDW AUDIOLOGISTS',
            title: 'PULL UP BANNER',
            image: Media19,
            company: 'Gingercats Creative',
        },
        {
            client: 'IMPERFECT INNOCENCE',
            title: 'HORROR SHORT FILM',
            video: Media20,
            company: 'University Project',
        },
        {
            client: 'BALDO VINOS',
            title: 'MENU',
            image: Media21,
            company: 'Gingercats Creative',
        },
        {
            client: 'UNIVERSAL PAINTS',
            title: 'NEWSPAPER ADVERT',
            image: Media22,
            company: 'Gingercats Creative',
        },
    ];

    const renderPortfolioCard = (p, i) => {
        return (
            <div className="card" key={i}>
                {p?.video && (
                    <video className="card-img-top" width="320" controls src={p.video}>
					</video>
                )}
                {p?.image && (<img className="card-img-top" src={p.image} alt={p.title} />)}
                <div className="card-body" style={{ backgroundColor: '#F1EBDE' }}>
                    <h5 className="card-title">{p.client} - <span className="pinkText">{p.title}</span></h5>
                    <p className="card-text"><small className="text-muted">{p.company}</small></p>
                </div>
            </div>
        );
    };

    return (
        <div className="card-columns">
            {portfolioItems.map((p, i) => {
                return renderPortfolioCard(p, i);
            })}
        </div>
    );
};

export default Portfolio;
