import './App.css';
import "./includes/css/bootstrap.min.css";
import "./includes/css/animate.min.css";

import Home from './Home';

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
