import React from 'react';
import { Timeline, Tag } from 'antd';

const Experience = (props) => {
    const {} = props;

    const renderExperienceCard = (duration, company, title, descriptions, skills) => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ paddingBottom:'20px' }}>
                <div className="skillBox">
                    <h5 className="skillBoxYear">{duration}</h5>
                    <h3 className="skillBoxName">{company}</h3>
                    <h1 className="skillBoxTitle">{title}</h1>
                    <p className='skillBoxDesc'>
                        {descriptions.map((d, i) => {
                            return <span key={i}>{d}<br /></span>;
                        })}
                    </p>
                    {skills.map((s) => {
                        return (
                            <Tag key={s} color={'#E4938C'} style={{ marginBottom: 10 }}>{s}</Tag>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <Timeline
            items={[
                {
                    key: '7',
                    color: '#E4938C',
                    children: renderExperienceCard(
                        '2023 - PRESENT', 'REACH ENGAGE DEVELOP', 
                        'Fulls Stack Developer', 
                        [
                            'PostX Messaging - A web and mobile app, multi-channel chat platform, that integrates with WhatsApp, Facebook Messaging, Facebook Comments and LinkedIn Comments. Built-in routing options, automated responses, response time management, chat transfers, multi-media support, lead management integration, escalation rules, template message and quick-reply supprt and QR code conversation initiation.',
                            'PostX Social Posting - Automated content scheduling platform for Facebook, LinkedIn and Instagram. Ability to Retract posts. Internal and External Review before posting. Creation of social posts from mobile app where a user can add configured watermarks. Client approval to post content using OTP\'s.'
                        ], 
                        ['WhatsApp API', 'Facebook Graph API', 'LinkedIn Community Management API', 'Postman', 'React', 'React Native', 'AWS Lambda', 'AWS CDK', 'PHP']
                    ),
                },
                {
                    key: '6',
                    color: '#E4938C',
                    children: renderExperienceCard(
                        '2020 - 2023',
                        'COMUNE.IT',
                        'Graphic Designer > Junior Developer > Full Stack Developer',
                        [
                            'Graphic Design: App store screenshots and app icon design for Google Play and Apple App Store. Brochure design.',
                            'Backend and frontend development. API creation. Mobile App development for Android and iOS.',
                            'Assisting Junior Developers with their work and performing code reviews. Briefing Designers and Developers on new projects.',
                            'Auditing code, creating technical specifications and roadmaps for new features.',
                            'Release management, minimal DevOps, researching and implementing tech stacks for new projects.'
                        ],
                        [
                            'AWS Lambda', 'AWS CDK', 'AWS Amplify', 'AWS ECR', 'AWS CLI', 'AWS SES',
                            'PHP', 'Yii', 'PostgreSql', 'Visual Studio Code', 'Jira', 'Bitbucket',
                            'React', 'React Native', 'Redux', 'Expo', 'Firebase', 'Xcode', 'Android Studio',
                            'Docker', 'Kubernetes',
                            'DynamoDB', 'GraphQL', 'Mapbox'
                        ]
                    ),
                },
                {
                    key: '5',
                    color: '#E4938C',
                    children: renderExperienceCard(
                        '2018 - 2019',
                        'FREELANCE',
                        'Developer',
                        [
                            'Developed a desktop application for Kobus Dekker Occupational Hygiene Consultancy.',
                            'They required an application that would read a CSV file and generate a PDF report after calucating Air and Dust Pollution in mines.',
                        ],
                        ['HTML', 'CSS', 'JavaScript', 'Electron']
                    ),
                },
                {
                    key: '4',
                    color: '#E4938C',
                    children: renderExperienceCard(
                        '2017 - 2020',
                        'GINGERCATS CREATIVE',
                        'Multimedia Designer',
                        [
                            'Design: Corporate Identity, Logo, Traditional Marketing Material.',
                            'Monthly social media content creation, advertising and reporting for Facebook and LinkedIn.',
                            'Animations and video editing.',
                            'Website design and development. Mobile and desktop app design.',
                        ],
                        ['Adobe Illustrator', 'Adobe Photoshop', 'Adobe XD', 'Adobe After Effects', 'Adobe Premiere Pro', 'HTML', 'CSS', 'JavsScript']
                    ),
                },
                {
                    key: '3',
                    color: '#E4938C',
                    children: renderExperienceCard(
                        '2016',
                        'UNIVERSITY TUTOR',
                        'Advanced Markup Languages',
                        ['Assisted students with their work as well as marking assignments, practicals and final projects.'],
                        []
                    ),
                },
                {
                    key: '2',
                    color: '#E4938C',
                    children: renderExperienceCard(
                        '2016',
                        'BACHELOR OF INFORMATION SCIENCE, MULTIMEDIA',
                        'University of Pretoria, Hatfield',
                        ['Key Subjects: Multimedia, Computer Science', 'Specializations: UX and UI Design and Development, Web and Frontend Development, Game Design and Development'],
                        ['C++', 'C#', 'Java', 'Bash', 'PHP', 'Html', 'CSS', 'JQuery', 'JavaScript', 'IntelliJ', '3DS Max', 'Unity', 'Adobe Flash', 'WebGL']
                    ),
                },
                {
                    key: '1',
                    color: '#E4938C',
                    children: renderExperienceCard(
                        '2013',
                        'NATIONAL SENIOR CERTIFICATE',
                        'Sutherland Highschool, Centurion',
                        ['Key Subjects: English Home Language, Afrikaans First Additional Language, Mathematics, Life Orientation, Information Technology, Life Sciences, Physical Sciences'],
                        ['Delphi', 'Microsoft Office']
                    ),
                },
            ]}
        />
    );
};

export default Experience;